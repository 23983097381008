import { Namespace, ResourceKey } from 'i18next';
import { DefaultNamespace, i18n } from 'next-i18next';

interface IndexedResourceKey {
  [key: string]: string;
}

/**
 * Should use with a namespace only will upgrade to use with multiple namespaces
 * This is used specifically for SEO purposes and avoid extend long task cause by useTranslation (fixedT) at initial phase.
 * @param ns
 * @returns
 */
const useCustomTranslation = <N extends Namespace = DefaultNamespace>(ns?: N | Readonly<N>) => {
  const { resources, lng, defaultNS } = i18n?.options || {};

  const t = (key: string) => {
    if (!resources || !lng || !defaultNS) {
      return key;
    }

    if (Array.isArray(ns)) {
      throw new Error('useCustomTranslation does not support multiple namespaces yet');
    }

    const namespace = ns || defaultNS;
    const result = resources[lng][namespace as string] as ResourceKey & IndexedResourceKey;

    return result[key] || key;
  };

  return { t, i18n };
};

export default useCustomTranslation;
