import { UiStore, UiStoreActions, UiStoreObservables } from './types';

export const activeSegment = (state: UiStoreObservables) => {
  return state.activeSegment;
};

export const segments = (state: UiStoreObservables) => {
  return state.segments;
};

export const lang = (state: UiStoreObservables) => {
  return state.lang;
};

export const toastStack = (state: UiStoreObservables) => {
  return state.toastStack;
};

export const modalStack = (state: UiStoreObservables) => {
  return state.modalStack;
};

export const activeModalIdx = (state: UiStoreObservables) => {
  return state.activeModalIdx;
};

export const isOpenAppSmartBanner = (state: UiStoreObservables) => {
  return state.isOpenAppSmartBanner;
};

export const deeplink = (state: UiStoreObservables) => {
  return state.deeplink;
};

export const isModalOpen = (state: UiStoreObservables) => {
  return state.modalStack.length > 0;
};

export const onPdvInteractedListener = (state: UiStoreObservables) => {
  return state.onPdvInteractedListener;
};

export const getSearchQuery = (state: UiStoreObservables) => {
  return state.searchQuery;
};

export const topBrands = (state: UiStoreObservables) => {
  return state.topBrands;
};

export const topSearches = (state: UiStoreObservables) => {
  return state.topSearches;
};

export const footerContent = (state: UiStoreObservables) => {
  return state.footerContent;
};

export const showInteractiveJourneyConfetti = (state: UiStoreObservables) => {
  return state.showInteractiveJourneyConfetti;
};

export const actions = (state: UiStore): Pick<UiStore, keyof UiStoreActions> => {
  return {
    addToast: state.addToast,
    setToastStack: state.setToastStack,
    showModal: state.showModal,
    _setActiveModalIdx: state._setActiveModalIdx,
    _setModalStack: state._setModalStack,
    closeAppSmartBanner: state.closeAppSmartBanner,
    setDeeplink: state.setDeeplink,
    setActiveSegment: state.setActiveSegment,
    addPdvInteractedListener: state.addPdvInteractedListener,
    removePdvInteractedListener: state.removePdvInteractedListener,
    setSegments: state.setSegments,
    setSearchQuery: state.setSearchQuery,
    setShowInteractiveJourneyConfetti: state.setShowInteractiveJourneyConfetti,
  };
};
