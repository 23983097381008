import clsx from 'clsx';
import { FC, memo } from 'react';
import withDynamicLoadIntersection from 'hocs/withDynamicLoadIntersection';
import { useDeviceType } from 'hooks/useDeviceType';

const DynamicDesktopFooterSection = withDynamicLoadIntersection(
  () => import('./DynamicDesktopFooterSection'),
  {
    rootMargin: '200px',
    threshold: 0,
  },
);

interface Props {
  className?: string;
}

const DesktopFooter: FC<Props> = ({ className }) => {
  const { isDesktop } = useDeviceType();

  return (
    <footer
      style={{
        contentVisibility: 'auto',
        //containIntrinsicHeight is not available for TS type
        //@ts-ignore
        containIntrinsicHeight: 1428,
      }}
      data-test-id="footer"
      className={clsx('mt-10 flex flex-col', className)}
    >
      {/* Only show DarkFooterDesktop when reach to bottom */}
      {isDesktop ? <DynamicDesktopFooterSection /> : null}
    </footer>
  );
};

export default memo(DesktopFooter);
