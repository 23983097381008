import { HandbagIcon, Heart2Icon, User2Icon } from '@zalora/zui-icons';
import clsx from 'clsx';
import { FC, ReactNode, Suspense, lazy } from 'react';
import { useDeviceType } from 'hooks/useDeviceType';
import useHydrated from 'hooks/useHydrated';
import ResponsiveHeaderSegments from './ResponsiveHeaderSegments';

const ResponsiveHeaderLogoSkeleton = (
  <div className="h-5 w-[148px] fill-grey-100 tablet:min-w-44" />
);
const ResponsiveHeaderLogo = lazy(() => import('./ResponsiveHeaderLogo'));

const DesktopHeaderNavigationRevamp = lazy(() => import('./DesktopHeaderNavigationRevamp'));

const ResponsiveHeaderSearchLoading = (
  <div className="h-10 rounded-full border-2 py-1 pl-4 pr-9 tablet:mx-4 tablet:block" />
);

const ResponsiveHeaderSearch = lazy(() => import('./ResponsiveHeaderSearch'));

const ResponsiveHeaderDynamicSectionLoading = (
  <>
    <div className="w-32">
      <User2Icon />
    </div>
    <Heart2Icon />
    <HandbagIcon />
  </>
);

const ResponsiveHeaderAsyncSection = lazy(() => import('./ResponsiveHeaderAsyncSection'));

interface Props {
  className?: string;
  shouldShowMobileSearch?: boolean;
  shouldShowMobileLogo?: boolean;
  TabletActionButton?: ReactNode;
}

const ResponsiveHeader: FC<Props> = ({
  className,
  shouldShowMobileSearch = true,
  shouldShowMobileLogo = true,
  TabletActionButton,
}) => {
  const { isDesktop } = useDeviceType();
  const isHydrated = useHydrated();
  const shouldShowSearch = (isDesktop || shouldShowMobileSearch) && isHydrated;
  const shouldShowLogo = (isDesktop || shouldShowMobileLogo) && isHydrated;

  return (
    <header
      className={clsx(
        'sticky -top-10 z-global bg-white px-4',
        'tablet:top-0',
        'desktop:px-12 desktop:shadow-responsiveHeader',
        className,
      )}
      style={{ WebkitTransform: 'translateZ(0)', transform: 'translateZ(0)' }}
    >
      <div
        className={clsx(
          'mx-auto flex max-w-screen-xl flex-col items-center gap-y-4 py-4',
          'desktop:pb-0',
        )}
      >
        <div
          className={clsx(
            'flex w-full flex-col gap-y-4',
            'tablet:flex-row tablet:items-center tablet:gap-x-8',
            'desktop:gap-x-20',
          )}
        >
          {shouldShowLogo ? (
            <Suspense fallback={ResponsiveHeaderLogoSkeleton}>
              <ResponsiveHeaderLogo />
            </Suspense>
          ) : (
            ResponsiveHeaderLogoSkeleton
          )}
          <div className={TabletActionButton ? 'tablet:basis-4/5' : 'tablet:basis-3/5'}>
            {shouldShowSearch ? (
              <Suspense fallback={ResponsiveHeaderSearchLoading}>
                <ResponsiveHeaderSearch />
              </Suspense>
            ) : (
              ResponsiveHeaderSearchLoading
            )}
          </div>

          <div className="hidden items-center gap-x-6 desktop:flex">
            {isDesktop ? (
              <Suspense fallback={ResponsiveHeaderDynamicSectionLoading}>
                <ResponsiveHeaderAsyncSection />
              </Suspense>
            ) : (
              ResponsiveHeaderDynamicSectionLoading
            )}
          </div>

          {TabletActionButton ? (
            <div className="hidden tablet:flex desktop:hidden">{TabletActionButton}</div>
          ) : null}
        </div>

        <div className="hidden w-full desktop:flex desktop:h-9">
          <Suspense fallback={null}>
            {isDesktop ? <DesktopHeaderNavigationRevamp /> : <ResponsiveHeaderSegments />}
          </Suspense>
        </div>
      </div>
    </header>
  );
};

export default ResponsiveHeader;
