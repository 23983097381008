import { FC, memo } from 'react';
import useSegments from 'hooks/header/useSegments';

const ResponsiveHeaderSegments: FC = () => {
  const segments = useSegments();

  if (!segments || segments.length === 0) {
    return null;
  }

  return (
    <div className="flex gap-2">
      {segments.map((segment) => (
        <a
          className="block rounded-tl-2xl rounded-tr-2xl px-4 py-2 font-bold uppercase first:-ml-4"
          href={`/s/${segment.id}`}
          data-test-id={`segment-${segment.id}`}
          key={segment.id}
        >
          {segment.name}
        </a>
      ))}
    </div>
  );
};

export default memo(ResponsiveHeaderSegments);
