import { IncomingMessage } from 'http';
import { HEADERS } from 'api/APIClient';
import { fetchBrandList } from 'api/cms';
import { getSegments } from 'api/segment';
import { fetchTopSearchKeyWords } from 'api/seo';
import { DEFAULT_LANGUAGE, i18nRegionalLanguages } from 'i18n/config';
import { orderSegments } from './../api/util';
import logger from './logger';

export const fetchActiveSegments = async (locale?: string) => {
  try {
    const headers: Record<string, string> = {};

    if (locale) {
      headers[HEADERS.CONTENT_LANG] = locale;
    }

    const segments = await getSegments(
      { filter: 'active' },
      {
        headers,
      },
    );

    return segments ? orderSegments(segments) : [];
  } catch (error) {
    logger.error('Error fetching segments', error);

    return [];
  }
};

export const getFooterContent = async (countryLocale: string, req?: IncomingMessage) => {
  try {
    const { content } = await import(`components/Layout/Footer/DarkFooter/cms/${countryLocale}`);

    return content;
  } catch (error) {
    logger.error(`Error fetching footer content: ${countryLocale} + ${req?.headers?.host}`, error);

    return null;
  }
};

const fetchLayoutData = async (
  locale: string,
  skipFetchingSegment = false,
  req?: IncomingMessage,
) => {
  const countryLocale = locale === DEFAULT_LANGUAGE ? i18nRegionalLanguages['en-SG'] : locale;

  const [segments, topBrands, topSearches, footerContent] = await Promise.all([
    !skipFetchingSegment ? fetchActiveSegments(locale) : [],
    fetchBrandList('footer_brands'),
    fetchTopSearchKeyWords(),
    getFooterContent(countryLocale, req),
  ]);

  return {
    segments,
    topBrands,
    topSearches,
    footerContent,
  };
};

export default fetchLayoutData;
