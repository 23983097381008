// https://usehooks-ts.com/react-hook/use-intersection-observer
import { RefObject, useEffect, useState } from 'react';

interface Args extends IntersectionObserverInit {
  freezeOnceVisible?: boolean;
  disconnectWhenIntersecting?: boolean;
}

function useIntersectionObserver(
  elementRef: RefObject<Element> | null,
  {
    threshold = 0,
    root = null,
    rootMargin = '0%',
    freezeOnceVisible = false,
    disconnectWhenIntersecting = false,
  }: Args,
): IntersectionObserverEntry | undefined {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();
  const [observer, setObserver] = useState<IntersectionObserver>();

  const frozen = entry?.isIntersecting && freezeOnceVisible;

  const updateEntry = ([e]: IntersectionObserverEntry[]): void => {
    setEntry(e);
  };

  useEffect(() => {
    if (!!entry?.isIntersecting && disconnectWhenIntersecting) {
      observer?.disconnect();
    }
  }, [entry, observer, disconnectWhenIntersecting]);

  useEffect(() => {
    const node = elementRef?.current; // DOM Ref
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || frozen || !node) {
      return;
    }

    const observerParams = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParams);

    setObserver(observer);

    observer.observe(node);

    return () => observer.disconnect();
  }, [elementRef, threshold, root, rootMargin, frozen]);

  return entry;
}

export default useIntersectionObserver;
