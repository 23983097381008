import { ZDTProduct } from '@zalora/doraemon-ts';
import apiClient, { APIRequestConfig } from 'api/APIClient';
import { getAPIError } from './../api/util';

export const getSegments = async (
  options: { shop?: string; filter?: 'active' | 'inactive' } = {},
  config: APIRequestConfig = {},
) => {
  const params: { [key: string]: string } = {};

  if (options.shop) {
    params.shop = options.shop;
  }

  if (options.filter) {
    params.filter = options.filter;
  }

  const response = await apiClient.get('/v1/catalog/segments', { ...config, params });

  if (!response.ok) {
    throw await getAPIError(response);
  }

  const { data } = await response.json();

  return (data as ZDTProduct.SegmentList).Segments;
};
