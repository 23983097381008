import { ZDTProduct } from '@zalora/doraemon-ts';
import { ProductIndexParams } from 'types/ProductIndex';
import { ZDTInteractiveStore, ZDTMisc } from 'types/ZDT';

export const SPECIAL_PAGES = {
  ALL: 'all',
  NEW: 'new',
  TOP: 'top',
  SALE: 'sale',
} as const;

const SPECIAL_PAGES_KEY = Object.values(SPECIAL_PAGES);

export type SpecialPage = (typeof SPECIAL_PAGES)[keyof typeof SPECIAL_PAGES];

export const enum CatalogType {
  CATALOG = 'catalog',
  SEARCH = 'search',
  ZIS = 'ZIS',
  PIP = 'PIP',
}

export interface PageInfoBase {
  // common props here
  specialPage?: SpecialPage;
  segment?: string;
  brandId?: string;
  categoryId?: string;
  subCategoryId?: string;
}

interface CatalogPageInfo extends PageInfoBase {
  catalogType: CatalogType.CATALOG;
}

interface SearchPageInfo extends PageInfoBase {
  catalogType: CatalogType.SEARCH;
}

interface ZISPageInfo extends PageInfoBase {
  catalogType: CatalogType.ZIS;
  storeInfo: ZDTInteractiveStore.Store;
}

export interface PIPPageInfo extends PageInfoBase {
  catalogType: CatalogType.PIP;
  productIndexInfo: ZDTProduct.IndexDetail;
  productIndexParams: ProductIndexParams;
  metaData: ZDTMisc.CmsResponse;
}

export type PageInfo = CatalogPageInfo | SearchPageInfo | ZISPageInfo | PIPPageInfo;

export const isZISPageInfo = (pageInfo: PageInfo): pageInfo is ZISPageInfo => {
  return pageInfo.catalogType === CatalogType.ZIS;
};

export const isSearchPageInfo = (pageInfo: PageInfo): pageInfo is SearchPageInfo => {
  return pageInfo.catalogType === CatalogType.SEARCH;
};

export const isCatalogPageInfo = (pageInfo: PageInfo): pageInfo is CatalogPageInfo => {
  return pageInfo.catalogType === CatalogType.CATALOG;
};

export const isPIPPageInfo = (pageInfo: PageInfo): pageInfo is PIPPageInfo => {
  return pageInfo.catalogType === CatalogType.PIP;
};

export const isSpecialPage = (key: string): key is SpecialPage => {
  return SPECIAL_PAGES_KEY.includes(key as SpecialPage);
};
