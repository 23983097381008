export const enum RootCategoryDepth {
  WITH_SEGMENT = '2',
  WITHOUT_SEGMENT = '1',
}

export enum Robots {
  INDEX_FOLLOW = 'index,follow',
  NOINDEX_FOLLOW = 'noindex,follow',
  NOINDEX_NOFOLLOW = 'noindex,nofollow',
}
