export const enum TestIdsCommon {
  BACK_TO_TOP_BUTTON = 'backToTopButton',
  CART_LINK = 'cartLink',
  LOADING_IMAGE = 'loadingImage',
  SKELETON = 'skeleton',
  NUMBER_OF_ITEMS_IN_CART = 'numberOfItemsInCart',
  TAP_TO_COPY_BUTTON = 'tapToCopyButton',
  RECOMMENDATION_FEED = 'recommendation-feed',
  GENERIC_ERROR_DIALOG = 'generic-error-dialog',
}
