/* eslint-disable @typescript-eslint/no-explicit-any */
import { Logger as PinoLogger, LoggerOptions } from 'pino';
import { getEnvConfiguration } from './env-configuration';
import isServer from './is-server';

let serverLogger: PinoLogger | null = null;

const LOG_LEVEL_ENV_KEY = 'LOG_LEVEL';
const DEFAULT_LOG_LEVEL = 'warn';
const LEVELS = ['debug', 'info', 'warn', 'error'] as const;

type Level = (typeof LEVELS)[number];
interface Logger {
  debug: (message: string, context?: any) => Promise<void>;
  info: (message: string, context?: any) => Promise<void>;
  warn: (message: string, context?: any) => Promise<void>;
  error: (message: string, context?: any) => Promise<void>;
}

/**
 * It returns a logger (pino) if we're on the server, otherwise it returns the console
 */
const getLogger = async (): Promise<PinoLogger<LoggerOptions> | Console> => {
  if (!isServer()) {
    return console;
  }

  if (serverLogger === null) {
    // using dynamic import to avoid including 'pino' to the client-side bundle
    serverLogger = await import('pino').then((res) =>
      res.default({
        level: getEnvConfiguration(LOG_LEVEL_ENV_KEY) || DEFAULT_LOG_LEVEL,
      }),
    );
  }

  return serverLogger || console;
};

const log = async (level: Level, message: string, context?: any) => {
  const logger = await getLogger();

  logger[level](context, message);
};

const logger: Logger = LEVELS.reduce((result: Logger, level: Level) => {
  result[level] = async (message: string, context?: any) => {
    log(level, message, context);
  };

  return result;
}, {} as Logger);

export default logger;
