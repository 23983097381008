import clsx from 'clsx';
import React, { FC } from 'react';
import { SkeletonItem } from './SkeletonItem';

interface Props {
  items?: number;
}

export const SkeletonBubble: FC<Props> = () => {
  return (
    <div
      className={'bubble-wrapper mx-auto flex w-full gap-4 tablet:gap-8 tablet:px-8 desktop:gap-8'}
    >
      {[...Array(10)].map((_, idx) => (
        <div
          key={idx}
          className={clsx('space-y-2', 'w-15 shrink-0 space-y-1 tablet:w-24 desktop:w-[90px]')}
        >
          <SkeletonItem
            width="w-full"
            height="h-0"
            className="rounded-full pt-[100%]"
          />
          <SkeletonItem width="w-4/5 mx-auto" />
          <SkeletonItem width="w-1/2 mx-auto" />
        </div>
      ))}
    </div>
  );
};
