import apiClient, { HEADERS } from 'api/APIClient';
import { ZDTMisc } from 'types/ZDT';
import { ApiResponse } from 'types/api/ApiResponse';
import { captureError } from 'utils/raven';
import { ApiError } from './apiError';
import { getAPIError } from './util';

export type StaticBlockItem =
  | 'text'
  | 'data'
  | 'banner'
  | 'body_start_block'
  | 'mobile_banner'
  | 'banner_full_width'
  | 'seo_text'
  | 'web_mobile_seo_text'
  | 'page_title'
  | 'meta_description'
  | 'meta_keywords'
  | 'meta_robots'
  | 'web_mobile_text'
  | 'start_date'
  | 'end_date'
  | 'priority'
  | 'navigation_menu'
  | 'h1_tag'
  | 'hreflang_tag';

type TypeStatic = 'staticBlock' | 'staticPage' | 'productIndex' | 'homepage';

export const fetchStaticCmsByType = async ({
  item,
  items,
  key,
  type = 'staticBlock',
  locale,
}: {
  key: string;
  item?: StaticBlockItem;
  items?: StaticBlockItem[];
  type?: TypeStatic;
  locale?: string;
}): Promise<ApiResponse<ZDTMisc.CmsResponse> | null> => {
  if (!key) {
    return null;
  }

  try {
    const res = await apiClient.get(`/v1/cms/${type}/${key}`, {
      params: { item: (item || items) as string | string[] },
      headers: locale
        ? {
            [HEADERS.CONTENT_LANG]: locale,
          }
        : {},
    });

    if (!res.ok) {
      throw await getAPIError(res);
    }

    const data = await res.json();

    return data;
  } catch (error) {
    const { message } = (error || {}) as ApiError | Error;

    // Ignore network related issues
    if (message === 'Failed to fetch') {
      return null;
    }

    captureError('Failed to fetch Static CMS data', {
      error,
      params: { key, type },
      tag: 'get-request',
    });

    return null;
  }
};

export const fetchBrandList = async (key: string): Promise<ZDTMisc.CmsBrand[]> => {
  try {
    const res = await apiClient.get(`/v1/cms/brandslist/${key}`);
    const data: ApiResponse<ZDTMisc.CmsResponse> = await res.json();

    return data.data.BrandList || [];
  } catch (_) {
    return [];
  }
};
