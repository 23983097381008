import clsx from 'clsx';
import { FC } from 'react';
import TopPages from 'components/Layout/Footer/TopPages/TopPages';
import withDynamicLoadIntersection from 'hocs/withDynamicLoadIntersection';

const MobileFooterLinks = withDynamicLoadIntersection(() => import('./MobileFooterLinks'));

interface Props {
  className?: string;
}

const MobileFooter: FC<Props> = ({ className }) => {
  return (
    <div className={clsx('mt-10 desktop:hidden', className)}>
      <TopPages type="row" />
      <MobileFooterLinks />
    </div>
  );
};

export default MobileFooter;
