import clsx from 'clsx';
import React from 'react';
import { getStaticAsset } from 'utils/domains';
import { SkeletonItem } from '.';

interface Props {
  showAddToBag?: boolean;
  className?: string;
}

export const SkeletonProduct = ({ showAddToBag, className }: Props) => {
  return (
    <div className={clsx('product-item-recommendation flex flex-col gap-4', className)}>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        alt="skeleton product image"
        className="w-full animate-pulse rounded-lg"
        style={{ aspectRatio: 160 / 215 }}
        src={getStaticAsset('/static-assets/images/product_skeleton.webp')}
      />
      <div className="flex flex-col gap-2 p-1">
        <SkeletonItem width="w-3/5" />
        <SkeletonItem />
        <SkeletonItem width="w-2/5" />
      </div>
      {showAddToBag ? <SkeletonItem height="h-10" /> : null}
    </div>
  );
};
