import clsx from 'clsx';
import React from 'react';
import { SkeletonItem } from './SkeletonItem';

interface Props {
  className?: string;
  ratio?: number;
  column?: number;
  hasTitle: boolean;
}

export const SkeletonBanner = ({ className, ratio, column, hasTitle }: Props) => {
  return (
    <div className={clsx('m-auto pb-1', className)}>
      {ratio && ratio > 0 && hasTitle ? <SkeletonItem width="w-4/5 mt-1 pt-5 mb-4" /> : null}
      <SkeletonItem
        height="h-0"
        className="banner-skeleton"
        style={ratio && ratio > 0 ? { paddingTop: `${(ratio * 100) / +(column || 1)}%` } : {}}
      />
    </div>
  );
};
