import dynamic, { DynamicOptions, Loader } from 'next/dynamic';
import { ComponentType, FC, startTransition, useEffect, useRef, useState } from 'react';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { useInvokeCallbackOnceWhen } from 'hooks/useInvokeCallbackOnceWhen';

const withDynamicLoadIntersection =
  <P extends object>(
    dynamicOptions: DynamicOptions<P> | Loader<P>,
    options?: {
      threshold?: number;
      rootMargin?: string;
      fallback?: JSX.Element;
    },
  ): FC<P> =>
  (props: P) => {
    const ref = useRef<HTMLDivElement>(null);
    const [DynamicComponent, setDynamicComponent] = useState<ComponentType<P> | null>(null);
    const { threshold = 0.5, rootMargin } = options || {};
    const entry = useIntersectionObserver(ref, {
      threshold,
      rootMargin,
      freezeOnceVisible: true,
      disconnectWhenIntersecting: true,
    });
    const isVisible = !!entry?.isIntersecting;
    const [hasLoaded, setHasLoaded] = useState(false);

    useInvokeCallbackOnceWhen(() => {
      const dynamicComponent = dynamic(dynamicOptions, {
        ssr: false,
        loading: () => options?.fallback || null,
      });

      setDynamicComponent(dynamicComponent);
    });

    useEffect(() => {
      if (isVisible) {
        startTransition(() => {
          setHasLoaded(true);
        });
      }
    }, [isVisible, setHasLoaded]);

    return hasLoaded && DynamicComponent ? (
      <DynamicComponent {...props} />
    ) : (
      <div
        ref={ref}
        className="w-full"
      >
        {options?.fallback || null}
      </div>
    );
  };

export default withDynamicLoadIntersection;
