import { ZDTProduct, ZDTSEO } from '@zalora/doraemon-ts';
import { QueryParams } from 'api/APIClient';
import { CAMPAIGN_PARAMS } from 'constants/catalog';
import { ZDTMisc } from 'types/ZDT';
import { PageInfo, SpecialPage } from './catalog-page-type';

export interface CatalogUrlChunks {
  params?: string;

  // Chunk names
  segment: Nullishable<string>;
  brandSlug: Nullishable<string>;
  categorySlug: Nullishable<string>;
  subcategorySlug: Nullishable<string>;

  // Chunk IDs
  brandId?: string;
  categoryId?: string;
  subcategoryId?: string;

  // Misc
  specialPageType?: SpecialPage;
}

const hasCampaignParams = (query: QueryParams) =>
  Object.keys(query).some((param) => CAMPAIGN_PARAMS.includes(param));

export const isCampaignPage = (pageInfo: PageInfo, query: QueryParams) => {
  return pageInfo?.specialPage === 'all' || hasCampaignParams(query as QueryParams);
};

export const buildBrandUrl = (
  brand: ZDTMisc.CmsBrand | ZDTSEO.Brand | ZDTProduct.Brand,
  segment?: string,
) => {
  let brandId = '';

  if ('Id' in brand && brand.Id) {
    brandId = brand.Id;
  } else if ('IdCatalogBrand' in brand && brand.IdCatalogBrand) {
    brandId = brand.IdCatalogBrand;
  }

  if (!brandId) {
    return '';
  }

  if (segment) {
    return `/c/${segment}/${brand.UrlKey}/b-${brandId}`;
  }

  return `/c/${brand.UrlKey}/b-${brandId}`;
};
