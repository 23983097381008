import clsx from 'clsx';
import { FC, memo } from 'react';
import ListColumn, { Item } from 'components/Layout/Footer/List/ListColumn';
import ListSeparator from 'components/Layout/Footer/List/ListSeparator';
import { TestIdsList } from 'constants/e2eIds/list-test-ids';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { useUiStore } from 'stores/ui';
import * as uiSelectors from 'stores/ui/selectors';
import { buildBrandUrl } from 'utils/catalog/catalog-url';

interface Props {
  type: 'column' | 'row';
}

const TopPages: FC<Props> = ({ type }) => {
  const { t } = useCustomTranslation();

  const topBrands = useUiStore(uiSelectors.topBrands);
  const topSearches = useUiStore(uiSelectors.topSearches);

  const ListComponent = type === 'column' ? ListColumn : ListSeparator;

  const brandList = topBrands.map((brand) => ({
    label: brand.Name,
    url: buildBrandUrl(brand),
  }));

  const keywordList = topSearches.reduce((acc, keyword) => {
    if (keyword.Urls?.length) {
      acc.push({
        label: keyword.KeywordName || '',
        url: keyword.Urls[0] as string,
      });
    }

    return acc;
  }, [] as Item[]);

  return (
    <div
      className={clsx(
        'mx-auto flex w-full max-w-screen-xl flex-col gap-4 border-b pb-4 text-grey-80 tablet:flex-row tablet:items-start',
        { 'px-8': type === 'column' },
        { 'px-4': type === 'row' },
      )}
    >
      <ListComponent
        className="flex-1"
        title={t('Top Brands')}
        data={brandList}
        linkToAll="/brands"
        testId={TestIdsList.TOP_BRANDS_LIST}
      />
      <ListComponent
        className="flex-1"
        title={t('Top Searches')}
        data={keywordList}
      />
    </div>
  );
};

export default memo(TopPages);
