import { ContextValue, StoreContext } from 'context/StoreContext';
import { useContext } from 'react';
import { shallow } from 'zustand/shallow';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { IgnorableError } from 'utils/ignorable-errors';

type ExtractState<S> = S extends { getState: () => infer T } ? T : never;

const createUseStoreHook = <T extends keyof ContextValue>(key: T) => {
  type StoreState = ExtractState<NonNullable<ContextValue[T]>>;

  const useStoreHook = <V extends (state: StoreState) => ReturnType<V>>(
    selector: V,
  ): ReturnType<V> => {
    const { [key]: store } = useContext(StoreContext);

    if (!store) {
      throw new IgnorableError(
        `❌ Store "${key}" was not found in <StoreContext>.\n\n` +
          `Did you pass "${key}" through preloadStoreState?\n\n` +
          'Hint: Pass an empty object "{}" if there are no default values.\n\n',
      );
    }

    return useStoreWithEqualityFn(store, selector, shallow);
  };

  return useStoreHook;
};

export default createUseStoreHook;
