import { useState, useEffect, startTransition } from 'react';

function useHydrated() {
  const [isHydrated, setIsHydrated] = useState(false);

  useEffect(() => {
    startTransition(() => {
      setIsHydrated(true);
    });
  }, []);

  return isHydrated;
}

export default useHydrated;
