import { ZDTSEO } from '@zalora/doraemon-ts';
import apiClient, { HEADERS, simpleFetch } from 'api/APIClient';
import { ApiResponse } from 'types/api/ApiResponse';
import { PageInfo } from 'utils/catalog/catalog-page-type';
import { ApiError } from './apiError';

const DEFAULT_TOP_SEARCH_LIMIT = 60;

export const fetchTopSearchKeyWords = async (
  limit: number = DEFAULT_TOP_SEARCH_LIMIT,
): Promise<ZDTSEO.Keyword[]> => {
  try {
    const res = await apiClient.get(`/v1/seo/keywords`, {
      params: {
        limit,
      },
    });
    const data = (await res.json()) as ApiResponse<ZDTSEO.Keywords>;

    return data.data.SeoKeywords || [];
  } catch (_) {
    return [];
  }
};

export const fetchCatalogSeoInfo = async (pageInfo: PageInfo, locale: string) => {
  const { categoryId, subCategoryId, segment, brandId, specialPage } = pageInfo;
  const res = await simpleFetch<ZDTSEO.CatalogInfo>(`/v1/seo/catalog`, {
    params: {
      ...(segment ? { segment_key: segment } : {}),
      ...(brandId ? { brand_id: brandId } : {}),
      ...(categoryId ? { category_id: categoryId } : {}),
      ...(subCategoryId ? { sub_category_id: subCategoryId } : {}),
      ...(specialPage ? { specialKey: specialPage } : {}),
    },
    headers: {
      [HEADERS.CONTENT_LANG]: locale,
    },
  });

  return res.data;
};

export const fetchCategories = async (segment: string, locale?: string) => {
  const headers: { [key: string]: string } = {};

  if (locale) {
    headers[HEADERS.CONTENT_LANG] = locale;
  }

  const res = await apiClient.get(`/v1/seo/categories/${segment}`, {
    headers,
  });

  if (!res.ok) {
    throw new ApiError({
      message: `Errors occurred when getting categories of segment: ${segment}`,
      status: res.status,
      url: `/v1/seo/categories/${segment}`,
    });
  }

  const { data }: ApiResponse<ZDTSEO.CategoryList> = await res.json();

  return data.Categories || [];
};
