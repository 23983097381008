import { Button } from '@zalora/zui';
import { FC, memo } from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { Item } from './ListColumn';

const DEFAULT_SHOWING_ITEMS = 30;

interface Props {
  isOpen: boolean;
  toggle: () => void;
  data?: Item[];
  linkToAll?: string;
  title: string;
}

const ListSeparatorToggle: FC<Props> = ({ data, linkToAll, title, toggle, isOpen }) => {
  const { t } = useCustomTranslation();

  if (!data || data.length === 0) {
    return null;
  }

  const showShowMoreButton = data.length > DEFAULT_SHOWING_ITEMS;

  return (
    <div className="mb-1 flex h-10 items-center justify-between uppercase">
      <h3 className="font-bold">{title}</h3>
      {linkToAll ? (
        <a
          href={linkToAll}
          className="underline"
          data-test-id="linkToAllPage"
        >
          {t('View All')}
        </a>
      ) : null}
      {!linkToAll && showShowMoreButton ? (
        <Button
          variant="link"
          size="sm"
          className="px-3 py-0 uppercase"
          onClick={toggle}
          data-test-id="show-more-btn"
        >
          {isOpen ? t('Show Less') : t('Show More')}
        </Button>
      ) : null}
    </div>
  );
};

export default memo(ListSeparatorToggle);
