import { useDisclosure } from '@zalora/zui';
import clsx from 'clsx';
import { FC, memo } from 'react';
import { Item } from './ListColumn';
import ListSeparatorToggle from './ListSeparatorToggle';

const DEFAULT_SHOWING_ITEMS = 30;

interface Props {
  className?: string;
  data?: Item[];
  linkToAll?: string;
  testId?: string;
  title: string;
}

const ListSeparator: FC<Props> = ({ className, data, linkToAll, testId, title }) => {
  const { isOpen, toggle } = useDisclosure(false);

  if (!data || data.length === 0) {
    return null;
  }

  const showData = isOpen ? data : data.slice(0, DEFAULT_SHOWING_ITEMS);

  return (
    <div
      data-test-id={testId}
      className={clsx('w-full', className)}
    >
      <ListSeparatorToggle
        isOpen={isOpen}
        toggle={toggle}
        data={data}
        linkToAll={linkToAll}
        title={title}
      />

      <div>
        <ul>
          {showData.map((item, id) => (
            <li
              key={item.label + item.url}
              className={clsx('my-1 inline-block whitespace-nowrap', {
                'border-l border-solid border-slate-900': id !== 0,
              })}
            >
              <a
                className="inline-block min-w-20 px-2 py-1.5 text-center"
                href={item.url}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default memo(ListSeparator);
