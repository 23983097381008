import { CatalogType } from 'utils/catalog/catalog-page-type';

export const PER_PAGE = 36;
export const SPONSORED_PRODUCT_PER_PAGE = 3;
export const MAX_TIMES_SHOW_VARIATION_TOOLTIP = 5;
export const MAX_TIMES_SHOW_SIZE_GUIDE_ONBOARDING = 3;
export const CatalogFilterName = {
  BRAND: 'brandIds[]',
  CATEGORY: 'categoryId',
  SIZE: 'sizeSystems[]',
  GENDER: 'gender',
  PRICE: 'price',
  COLOR: 'colors[]',
  OCCASION: 'occasion',
  DISCOUNT: 'discount',
  SELLER: 'seller_id',
  MATERIAL: 'material_composition',
  PATTERN: 'pattern',
  RANGE: 'range',
  CONDITION: 'condition',
  EARTH_EDIT: 'conscious_edit',
  SOLD_BY: 'seller_id',
  SHIP_FROM: 'ships_from',
  RATING: 'rating',
  MEMBERSHIP_ELIGIBLE: 'membership_eligible',
} as const;

export type FilterName = (typeof CatalogFilterName)[keyof typeof CatalogFilterName];

export const FILTER_TYPE = {
  SEARCHABLE_FILTER: 'alphabetIndex',
  COLOR: 'color',
  SIZE: 'size',
};
export const FILTER_REGULAR_GROUP = 0;

export const CAMPAIGN_PARAMS = [
  'cmpgnOne',
  'cmpgnTwo',
  'cmpgnThree',
  'cmpgnFour',
  'cmpgnFive',
  'cmpgnSix',
];

export const NUMBER_OF_ITEMS_PLACEHOLDER = '[NUMBER_OF_ITEMS]';

export const CATALOG_HAVING_SPONSORED_PRODUCTS = [CatalogType.CATALOG, CatalogType.SEARCH];

// To use ZSR suggestion, we need to add a flag to the abtest params
// which is required by the API
// can remove when API done rollout task
export const ZSR_SUGGESTION_FLAG = 'zeroSearchResultSuggestion_True';
