import { Button, useDisclosure } from '@zalora/zui';
import clsx from 'clsx';
import { FC, memo } from 'react';

const DEFAULT_SHOWING_ITEMS = 30;
const GROUP_LENGTH = 5;

export interface Item {
  label: string;
  url: string;
}

interface Props {
  className?: string;
  data?: Item[];
  testId?: string;
  title: string;
}

const group = (data: Nullishable<Item[]>, len: number) => {
  if (!data || data.length === 0) {
    return [];
  }

  return data.reduce((acc, item) => {
    let arr: Item[] = acc.pop() || [];
    const newGroup: Item[][] = [...acc];

    if (arr.length === len) {
      newGroup.push(arr);
      arr = [];
    }

    arr.push(item);
    newGroup.push(arr);

    return newGroup;
  }, [] as Item[][]);
};

const ListColumn: FC<Props> = ({ className, data, testId, title }) => {
  const { isOpen, toggle } = useDisclosure(false);
  const showShowMoreButton = data && data.length > DEFAULT_SHOWING_ITEMS;
  const showData = isOpen ? data : data?.slice(0, DEFAULT_SHOWING_ITEMS);
  const groupData = group(showData, GROUP_LENGTH);

  if (groupData.length === 0) {
    return null;
  }

  return (
    <div
      data-test-id={testId}
      className={clsx('w-full', className)}
    >
      <h3 className="mb-1 p-3 font-bold uppercase">{title}</h3>
      <div>
        <div className="flex flex-wrap">
          {groupData.map((group, index) => (
            <ul
              className="w-1/4 p-3 tablet:w-1/3"
              key={index}
            >
              {group.map((item) => (
                <li key={item.url}>
                  <a
                    className="inline-block w-full py-1"
                    href={item.url}
                  >
                    {item.label}
                  </a>
                </li>
              ))}
            </ul>
          ))}
        </div>

        {showShowMoreButton ? (
          <Button
            variant="link"
            size="sm"
            className="px-3 py-0"
            onClick={toggle}
            aria-label="Show More"
            data-test-id="show-more-btn"
          >
            {isOpen ? 'Show Less' : 'Show More'}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default memo(ListColumn);
