import { useEffect } from 'react';
import { ScreenSize } from 'types/ScreenSize';
import { registerScreenSizeListener } from 'utils/screen-size';

/**
 * Hook that allows a component to listen to screen size changes. This helps
 * fill in the gaps that responsive CSS can't.
 *
 * A good example is JS listeners that only need to run on specific screen
 * sizes. With this hook a consumer can register & unregister listeners when the
 * screen size changes, allowing us to present a truly responsive website.
 *
 */
const useScreenSizeListener = <T extends (screenSize: ScreenSize) => void>(callback: T) => {
  useEffect(() => {
    const cleanupFunction = registerScreenSizeListener(callback);

    return () => {
      cleanupFunction && cleanupFunction();
    };
  }, [callback]);
};

export default useScreenSizeListener;
