import { useState, useTransition } from 'react';
import SCREEN_SIZES from 'constants/screen-size';
import isServer from 'utils/is-server';
import { getScreenSizeName } from 'utils/screen-size';
import useScreenSizeListener from './useScreenSizeListener';

export const useDeviceType = () => {
  const [layoutName, setLayoutName] = useState(isServer() ? undefined : getScreenSizeName());
  const [isPending, startTransition] = useTransition();

  useScreenSizeListener((layout) => {
    startTransition(() => {
      setLayoutName(layout.name);
    });
  });

  const isMobile =
    layoutName === SCREEN_SIZES.LMOBILE.name || layoutName === SCREEN_SIZES.SMOBILE.name;
  const isDesktop = layoutName === SCREEN_SIZES.DESKTOP.name;
  const isTablet = layoutName === SCREEN_SIZES.TABLET.name;

  return { layoutName, isMobile, isDesktop, isTablet, isPending };
};
