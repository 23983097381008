import clsx from 'clsx';
import React from 'react';
import { SkeletonItem } from './SkeletonItem';
import { SkeletonProduct } from './SkeletonProduct';

export const SkeletonRecommendation = () => {
  return (
    <div className="space-y-4">
      <SkeletonItem
        width="max-w-xs"
        className="mx-auto"
      />

      <div className="mx-auto flex w-full overflow-hidden px-4 pb-4">
        {[...Array(4)].map((_, idx) => (
          <div
            key={idx}
            className={clsx(
              'w-2/5 min-w-[40vw] rounded-lg px-2',
              'tablet:w-1/4 tablet:min-w-[25vw] tablet:px-4',
              'desktop:w-1/4 desktop:min-w-0',
            )}
          >
            <SkeletonProduct />
            <SkeletonItem
              height="h-10"
              className="mt-2"
            />
          </div>
        ))}
      </div>
    </div>
  );
};
