import useSWRImmutable from 'swr/immutable';
import { useUiDispatch, useUiStore } from 'stores/ui';
import * as uiSelectors from 'stores/ui/selectors';
import { fetchActiveSegments } from 'utils/server-layout-data';

const useSegments = () => {
  const segmentList = useUiStore(uiSelectors.segments);
  const uiDispatch = useUiDispatch();

  useSWRImmutable(segmentList.length ? null : 'catalog/segments', fetchActiveSegments, {
    onSuccess: (segments) => uiDispatch.setSegments(segments),
  });

  return segmentList;
};

export default useSegments;
